import React, { createContext, FC, useState } from "react"

type PicturesContext = {
    visuelHomepage: string | null,
    setVisuelHomepage: (newVisuel: string) => void,
    visuel2Homepage: string | null,
    setVisuel2Homepage: (newVisuel: string) => void,
    customLogo: string | null,
    setCustomLogo: (newVisuel: string) => void,
    picturesLoading: boolean,
    setPicturesLoading: (laoding: boolean) => void
}

type PicturesContextProviderType = {
    children: any
}

export const PicturesContext = createContext<PicturesContext>({
    visuelHomepage : null,
    setVisuelHomepage(): void {},
    visuel2Homepage : null,
    setVisuel2Homepage(): void {},
    customLogo : null,
    setCustomLogo(): void {},
    picturesLoading : false,
    setPicturesLoading(): void {},
})

export const PicturesContextProvider: FC<PicturesContextProviderType> = ({children}) => {

    const [ visuelHomepage, setVisuelHomepage ] = useState<string | null>(null)
    const [ visuel2Homepage, setVisuel2Homepage ] = useState<string | null>(null)
    const [ customLogo, setCustomLogo ] = useState<string>("")
    const [ picturesLoading, setPicturesLoading ] = useState<boolean>(false)


    return <PicturesContext.Provider value={{
        visuelHomepage,
        setVisuelHomepage,
        visuel2Homepage,
        setVisuel2Homepage,
        customLogo,
        setCustomLogo,
        picturesLoading,
        setPicturesLoading
    }}>
        {children}
    </PicturesContext.Provider>
}